// Room Page Slider - Show Images on load
$(".slider-for").on("init", function (event, slick) {
  var loadedSlides = $(".slider-for").find(".slideImageContainer");
  for (i = 0; i < loadedSlides.length; i++) {
    loadedSlides[i].style.display = "inherit";
  }
});

// Precinct Page Slider - Show Images on load
$(".precinctSlider").on("init", function (event, slick) {
  var loadedSlides = $(".precinctSlider").find(".slideImageContainer");
  for (i = 0; i < loadedSlides.length; i++) {
    loadedSlides[i].style.display = "inherit";
  }
});

function setHeightUnset() {
  const body = document.querySelector("#page_home");
  if (body) {
    body.style.setProperty("height", "unset", "important");
  }
}

// Apply the height unset style on load and resize
window.addEventListener("resize", setHeightUnset);
window.addEventListener("load", setHeightUnset);

// Observe changes to the element's attributes
const bodyObserver = new MutationObserver((mutationsList) => {
  for (let mutation of mutationsList) {
    if (mutation.type === "attributes" && mutation.attributeName === "style") {
      setHeightUnset();
    }
  }
});

// Start observing the body element
const body = document.querySelector("#page_home");
if (body) {
  bodyObserver.observe(body, {
    attributes: true, // Observe attribute changes
    attributeFilter: ["style"], // Only observe changes to the style attribute
  });
}

// Ensure the style is applied initially
setHeightUnset();

document.addEventListener("DOMContentLoaded", function () {
  function enhancedReadMore() {
    const mainIntro = document.getElementById("main-intro");
    if (!mainIntro) return;

    const readMoreBtn = document.querySelector(".read-more .more");
    const closeBtn = document.querySelector(".read-more .less");

    function checkContent() {
      const image = mainIntro.querySelector("img");
      const textContent = mainIntro.querySelector("p");

      if (image && textContent) {
        mainIntro.classList.add("has-image-text-content");
      } else {
        mainIntro.classList.remove("has-image-text-content");
      }
    }

    // Check the content initially
    checkContent();

    // Event listener for Read More button
    readMoreBtn?.addEventListener("click", function () {
      mainIntro.classList.add("open");
      readMoreBtn.style.setProperty("display", "none");
      closeBtn?.style.setProperty("display", "inline-block");
    });

    // Event listener for Close button
    closeBtn?.addEventListener("click", function () {
      mainIntro.classList.remove("open");
      closeBtn.style.setProperty("display", "none");
      readMoreBtn?.style.setProperty("display", "inline-block");
    });
  }

  // Initialize the enhancedReadMore function
  enhancedReadMore();
});
$(".popup-vimeo").magnificPopup({
  type: "iframe",
  mainClass: "mfp-fade",
  removalDelay: 160,
  preloader: !1,
  fixedContentPos: !1,
});
